.cardnf{
    background-image: url(../../../public/assets/img/bg-card.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 8px;
    padding: 0px;
}
.cardnf .ant-card-body {
    padding: 12px !important;
}
.cardnf .item-image-cover{
    width: 100%;
    padding-top: 100%; 
    border-radius: 8px;
    background-color: black;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
}
.cardnf .item-image-cover>div{
    position:  absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
}

.space-cs{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mb-ant-0{
    margin-bottom: 0px !important;
}

.btnnfs{
    background-color: #000;
    border: none;
    border-radius: 12px;
    color: #fff !important;
    padding: 0 20px;
}



.btnnfs:hover{
    background-color: #000;
}

.slectradius>.ant-select-selector{
    border-radius: 12px !important;
}

.drawercs .ant-drawer-content{
    border-top-left-radius: 8px !important;
}
.drawercs .ant-drawer-body{
    padding: 5px !important;
}

.drawercs .ant-drawer-header-title{
    flex-direction: row-reverse
}

.drawercs .ant-drawer-close{
    border: 1px solid var(--secondary-color);
    border-radius:50%;
    width: 28px !important;
    height: 28px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.drawercs .ant-drawer-title{
    font-size: 25px !important
}

.drawercs .ant-drawer-close{
    font-weight: bold;
    color: var(--secondary-color) !important;
    margin-right: 0px !important;
}

@media (max-width: 991px){
    .mblBorder{
        border-bottom: 2px solid #000;
    }
    .mblnone{
        display: none;
    }
    .gapmbl{
        gap: 5px !important;
    }

    .cardby{
        margin-bottom: 20px;
    }
    .cardby .ant-card-body{
        padding: 8px !important;
    }
    .w-100mbl{
        width: 98% !important;
    }
    .rowmbl{
        flex-wrap: nowrap !important;
        overflow-x: auto;
    }
    .drawercs .ant-drawer-content-wrapper{
        width: 100% !important;
        height: 85vh;
        top: 5%;
        border-radius: 12px;
        padding: 10px;
    }
}



.rowmbl::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.rowmbl::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
    height: 5px;
    border-radius: 12px;
}

.rowmbl::-webkit-scrollbar-thumb
{
	background-color: #000000;
    border-radius: 12px;
}


.favorite:hover{
    color: rgb(29, 28, 28);
}
