.collection-banner{
    position: relative;
    background-size:cover;
    background-attachment:fixed;
    background-position:center;
    width: 100%;
    height: 70vh;
}
.collection-banner>div{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    left: 0;
    bottom: 20px;
}