.quantity-counter  .ant-input-number-input{
    text-align: center !important;
  }
.quantity-counter  .ant-input-number-input:focus{
outline: none;
}
.nextrad{    
    border-radius: 0 !important;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
}

.prevrad{
    border-radius: 0 !important;
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
}