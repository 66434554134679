.editbtn{
    position: absolute;
    top: 0%;
    left: 50%;
    background-color: white;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(105%, 50%);
    border: 1px solid var(--repla);
    border-radius: 8px;
}

.maindiv>img{
    position: relative;
}

.dnon{
    display: none !important;
}