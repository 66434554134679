
 .dropping-soon-item-image-cover{
    height: 250px;
    width: 100%;
    background-color:black;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow:hidden;
}
.dropping-soon-item-image-cover image{
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

.overlayblur{
    background-color: rgba(255,255,255, 0.7);
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    z-index: 111;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imgcir img{
    width: 70px;
    height: 70px;
    background-color: #000;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
}

/* dropping sooon slider */
.dropping-soon-item-cover-one-two{
    display: flex;
    justify-content: center;
}
.imgsiz-n{
    margin-bottom: 5vw;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--nf-sounds-secondary) !important;
    background-size: cover !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    cursor: pointer;
}
.dropping-soon-item-cover-one-two .imgsiz-n {
    margin-bottom: 0 !important;
}
.img1{
    transform: translate(45%, 25%);
}

.img2{
    position: relative;
    z-index: 1;
    transform: translate(22%, 12%);
}

.img3{
    position: relative;
    z-index: 3;
}

.img4{
    position: relative;
    z-index: 1;
    transform: translate(-22%, 12%);
}

.img5{
    transform: translate(-45%, 25%);
}
.dropping-soon-item-cover-three{
    display: flex;
    justify-content: center;
}
.dropping-soon-item-cover-three .imgsiz-n {
    margin-bottom: 4vw;
}
.dropping-soon-item-cover-three .img1{
    transform: translate(22%, 25%);
}
.dropping-soon-item-cover-three .img2{
    transform: translate(0%, 0%)
}
.dropping-soon-item-cover-three .img3{
    transform: translate(-22%, 25%);
    z-index: 0;
}



@media (max-width: 576px){
    .bg-signup .one-line{
        font-size: 28px !important;
    }
}
@media (max-width: 420px){
    .bg-signup .one-line{
        font-size: 24px !important;
    }
}

.dropping-soon-slider .slick-next:before, .dropping-soon-slider .slick-prev:before {
    font-size: 45px !important;
}
.dropping-soon-slider .slick-next, .dropping-soon-slider .slick-prev {
    width: 50px !important;
    height: 50px !important
}
@media (max-width: 576px){
    .dropping-soon-slider .slick-next:before, .dropping-soon-slider .slick-prev:before {
        font-size: 30px !important;
    }
    .dropping-soon-slider .slick-next, .dropping-soon-slider .slick-prev {
        width: 35px !important;
        height: 35px !important
    }
}