.cart-bg{
    background-image: url(../../../public/assets/img/bg-1.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.amount{
    background-color: #fff;
    border-radius: 8px;
    padding: 5px 15px;
    box-shadow: inset 0px 1px 6px 0px #3e3c3c;
  
    white-space: nowrap;
}
.w-100px{
    width: 100px;
}
@media (max-width: 991px){
    .fontmbl{
        font-size: 14px !important;
    }
    .amount {
        padding: 0px 10px;
    }
}