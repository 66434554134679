.story-drawer .ant-modal-header{
    border-bottom: none;

}
.story-cover{
    height: 100%;
    display: flex;
    justify-content: center;
}
.story-cover .stories{
    width: 400px;
    position: relative;
}
.stories .story{
    height: calc(100vh);
    background-color: var(--nf-sounds-secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.stories .header{
    position: absolute;
    left: 40px;
    top: 5px;
}