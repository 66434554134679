.artist-card{
    display: flex !important;
}
.artist-card .center{
    width: calc(100% - 80px)
}
.search-bar{
    width: 480px;
}
@media screen and (max-width: 576px) {
    .search-bar{
        width: 400px;
    }
}
@media screen and (max-width: 470px) {
    .search-bar{
        width: 300px;
    }
}
@media screen and (max-width: 350px) {
    .search-bar{
        width: 270px;
    }
}