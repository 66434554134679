
  .video_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
  }
  .video_container-full-screen {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999999;
    background-color: black;
  }
  
  .player__wrapper {
    position: absolute;
    left: 0;
    bottom: 0;
    min-width: 100% !important;
    min-height: 100% !important;
  }
  .player {
    object-fit: cover;
    padding: 0;
    margin: 0;
  }
 
   
/* controls component */
.control_Container {
    background-color: rgba(0, 0, 0, 0.6);
    background-repeat: no-repeat !important;
    background-size: contain !important;
    background-position: center center !important;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    flex-direction: column;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    transition-property:all;
    transition-timing-function:ease;
    transition-duration:0.2s;
  }
.control_Container .control-button {
  border: none !important;
  background-color: transparent;
  }
.mid__container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 0;
  top:0;
  width: 100%;
  height: calc(100%)
  }
.bottom__container{
  position: relative;
  left: 0;
  bottom:6px;
  } 
.slider__container {
  display: flex;
  align-items: center;
  }
.video_container .video{
  width:99%;
}