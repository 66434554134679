.login-signup-button{
    display: flex !important;
    cursor: pointer;
}
.login-signup-button .center{
    width: calc(100%)
}

.login-signup-button h5{
    font-weight: 600 !important;
    font-size: 16px !important;
}