.btn-width{
    width: 180px;
    white-space: nowrap;
}



.w-200{
    width: 200px;
    white-space: normal;
}

.w-100px{
    width: 100px;
}
.w-50px{
    width: 100px !important;
}



.colortext{
    color: #fff !important;
}

.row-hover:hover .btnhover .colortext{
    color: #000 !important;
}
.music-table{
    border-collapse: collapse;
    border-spacing: 4px;
}
.music-table tbody:before, .music-table tbody:after{
    content: '';
    display: block;
    height: 20px;
  }
.music-table tr{
    cursor: pointer;
}
.bgchange{
    background-image: linear-gradient(to right, rgb(3, 3, 3), rgb(110,110,110) , rgb(103,103,103));
    border-radius: 8px;
    overflow: hidden;
    border: none !important
}

.bgchange td p{
    color: #fff ;
}
.show-on-mobile{
    display: none;
}
@media (max-width: 768px){
    .bgchange{
        /* background: var(--nf-sounds-secondary) !important; */
    }
    .tableResponsive{
        /* overflow-x: auto;
        white-space: nowrap; */
    }
}
@media (max-width: 868px){
    .hide-on-mobile{
        display: none !important;
    }
    .show-on-mobile{
        display: flex;
    }
}