.tab-button{
    background:transparent !important;
    border: none !important;
    box-shadow: none !important;
}
.widthnav{
    width: 98%;
    margin: 0 auto;
}
.mainNavbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.navBar{
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px;
}

.navbarand{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.hamburger{
    display: none;
}


.navBar .menubar{
    width: 100%;
    transition: .5s all;
}

.navBar .menubar>ul{
    list-style: none;
    display: flex;
    margin-bottom: 0px;
    align-items: center;
    width: 100%;
    transition: .5s all;
}

.navBar .menubar>ul li a{
    display: block;
    padding: 10px 20px;
    color: #000;
    font-weight: 600;
    text-decoration: none;
    text-transform: capitalize;
    transition: .5s all;
}

.navBar .menubar>ul li a:hover{
    opacity: 1;
}
.sizelogo{
    width: 100px;
}
.sidebar-drawer .ant-drawer-content{
    border-top-left-radius: 8px !important;
}
.sidebar-drawer .ant-drawer-body{
    padding: 5px !important;
}

.sidebar-drawer .ant-drawer-header-title{
    flex-direction: row-reverse
}

.sidebar-drawer .ant-drawer-close{
    border: 1px solid var(--secondary-color);
    border-radius:50%;
    width: 28px !important;
    height: 28px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebar-drawer .ant-drawer-title{
    font-size: 25px !important
}

.sidebar-drawer .ant-drawer-close{
    font-weight: bold;
    color: var(--secondary-color) !important;
    margin-right: 0px !important;
}

@media (min-width: 320px) and (max-width: 480px){
    .sizelogo{
        width: 70px;
    }
    .navBar .menubar{
        width: 80% !important;
    }
}

@media (max-width: 1200px){
    
    .hamburger{
        display: block;
    }
    .navBar .menubar{
        display: none;
    }
    .navBar .menubar.hide{
        opacity: 0;
        visibility: hidden;
        transition: .5s all;
        right: -100%;
    }
    .navBar .menubar.active{
        opacity: 1;
        visibility: visible;
        right: 0%;
        transition: .5s all;
    }   
    .hamburger{
        display: block;
    }
    .widthnav{
        width: 98%;
    }
}
@media (max-width: 676px){
    .search-bar{
        display: none;
    }
}