* {
    box-sizing: border-box;
  }
  
  body {
    font-family: sans-serif;
    background: #f2f2f2;
  }
  .player-cover{
    position: fixed !important;
    bottom: 0px !important;
    left:0;
    z-index: 99999;
    width: 100%;
    max-width: 100vw;
    display: flex;
    justify-content: center;
  }
  .player-cover-hide{
    position: fixed !important;
    bottom: -300px !important;
  }
  .player{
    background: linear-gradient(to right, rgba(0, 0, 0, 1) 0%, 36.10149919986725%, rgba(161, 161, 161, 1) 72.2029983997345%, 86.10149919986725%, rgba(97, 97, 97, 1) 100%);
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .animation{
    transition-property:all;
    transition-timing-function:ease;
    transition-duration:0.5s;
  }
  .player-right-controls-cover{
    position: relative;
  }
  .player-right-controls-cover>button{
    position: absolute;
    top: 2px;
    right: 2px;
  }
  .player-right-controls{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .player marquee{
    display: none;
  }
  .player>div:first-child{
    width: 250px;
  }
  .player>.player-middle{
    width: 50%;
  }
  .player>div:last-child{
    width: 250px;
    position: relative;
    display: flex; 
    align-items: flex-end;
    height: 100%;
  }
  .player>div:last-child .move-buttons{
    position: absolute;
    right: 0px;
    top: 0px
  }
  .audio-player {
    background: #c1b6bc;
  }
  
  .inner {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  @media (max-width: 765px){
    .player{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .player>div:first-child, .player>.player-middle{
      width: 100%;
    }
    .player>div:last-child .move-buttons{
      position: static;
    }
    .player marquee{
      display: flex;
    }
    .player marquee h5{
      font-size: 12px !important;
    }
    .player .authordiv{
      display: none;
    }
  }
  @media (max-width: 768px)
  {
    .hide-volume{
      display: none;
    }
    .player-right-controls{
      display: none;
      justify-content: center;
      align-items: center;
    }
  }
  @media (max-width: 576px){
    .player{
      width: 100% !important;
    }
  }
  /* audio info */
  .audio-info {
    display: flex;
    gap: 20px;
  }
  
  .text {
    color: #f50;
  }
  
  .title {
    color: #fff;
    font-weight: 100;
    font-size: 32px;
    margin-bottom: 0;
    padding: 2px;
    line-height: 37px;
    background-color: rgba(0,0,0,.8);
  }
  
  /* audio image */
  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .audio-image {
    width: 300px;
    height: 300px;
    background: #1e1e1e; 
  }
  
  .audio-icon {
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background: #989898;
  }
  
  .audio-image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    vertical-align: middle;
  }
  
  /* controls */
  .controls-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  
  .controls button, .volume button {
    border: none;
    background-color: transparent;
    margin-right: 8px;
    cursor: pointer;
  }
  
  button svg {
    font-size: 20px;
  }
  
  .volume {
    display: flex;
    align-items: center;
  }
  
  .volume button {
    margin: 0;
  }
  
  .progress {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
  }
  
  /* time */
  .time {
    color: #333;
    font-size: 13px;
    line-height: 46px;
  }
  
  .time.current {
    color: #f50;
  }
  
  /* Top bar */
  .top__bar {
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-align: center;
    padding-top: .5rem;
    padding-bottom: .5rem;
    background-color: #fafafa;
    border-bottom: 1px solid #f0f0f2;
  }
  
  .top__bar a {
    color: inherit;
  }
  
  .top__bar p {
    margin: 0;
  }

  .music-button-transparent{
    background-color: transparent !important;
    border: none !important;
    color: #dfdfe7 !important;
  }
  .music-button-transparent:hover{
    color: #fff !important;
  }

