.subscriptions-table{
    border-collapse: separate;
    border-spacing: 0 12px;
    width: 100%;
}
.subscriptions-table thead tr td{
   border-bottom: 1px solid var(--nf-sounds-secondary);
}
.subscriptions-table tbody tr td{
    background-color: var(--nf-sounds-secondary);
    overflow: hidden;
}
.subscriptions-table tbody tr td:first-child{
    border-radius: 8px 0px 0px 8px;
}
.subscriptions-table tbody tr td:last-child{
    border-radius: 0px 8px 8px 0px;
}

.user-profile-cover{
    display: flex;
    justify-content: space-between;
}
.user-profile-cover .user-profile-left{
    width: 200px;
}
.user-profile-cover .user-profile-right{
    padding-top: 50px;
    width: calc(100% - 230px);
}

@media (max-width: 1200px){
    .user-profile-cover{
        flex-direction: column;
    }
    .user-profile-cover .user-profile-left{
        width: 100%;
    }
    .user-profile-cover .user-profile-right{
        padding-top: 0;
        width: calc(100%);
    }
}