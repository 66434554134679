.benefit-cover-1{
   display: flex;
}
.benefit-cover-2, .benefit-cover-3, .benefit-cover-4{
    display: none;
 }
.benefit-box span{
    color: var(--nf-sounds) !important;
    text-transform: uppercase;
}
@media (max-width: 880px){
    .benefit-cover-1{
        display: none;
    }
    .benefit-cover-2{
        display: flex;
    }
}
@media (max-width: 500px){
    .benefit-cover-2{
        display: none;
    }
    .benefit-cover-3{
        display: flex;
    }
}

@media (max-width: 450px){
    .benefit-cover-3{
        display: none;
    }
    .benefit-cover-4{
        display: flex;
    }
}