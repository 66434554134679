
.show-section{
    display: none !important;
}
@media (max-width: 991px){
    .hide-section{
        display: none !important;
    }
    .show-section{
        display: flex !important;
    }
}