.tablebg .ant-table{
    background-color: transparent !important;
}

.tablebg .ant-table-thead > tr > th{
    background-color: transparent !important;
}

.tablebg .ant-table-tbody > tr > td{
    border-color: black !important;
}

.tablebg .ant-table-thead > tr > th{
    border-color: #000 !important;
}

.tabsub .ant-table-tbody tr{
    background: url(../../../public/assets/img/black-bg.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    mix-blend-mode: hard-light;
}


.tabsub .ant-table-tbody > tr.ant-table-row:hover > td{
    background: transparent !important;
}


.tabsub .ant-table-tbody > tr > td{
    padding: 0px !important;
}

.tabsub .ant-table-thead > tr > th{
    background: transparent !important;
}

.tabsub .ant-table{
    background-color: transparent !important;
}

@media (max-width: 991px){
    .mt-5{
        margin: 0px !important;
    }
    .pt-5{
        padding: 0px !important;
    }
}

.user-profile .ant-tabs-tab{
    border-radius: 8px !important;
    background-color: transparent !important;
    color: var(--nf-sounds-secondary) !important;
    font-weight: 600 !important;
    margin-right: 6px !important;
    border-color: transparent !important;
}
.user-profile .ant-tabs-tab:last-child{
    margin-right: 0 !important;
}
.user-profile .ant-tabs-tab-active{
    color: white !important;
    background-color: var(--nf-sounds-secondary) !important;
    border-radius: 8px 8px 0 0 !important;
}
.user-profile .ant-tabs-tab-active div{
    color: white !important
}
.user-profile .ant-tabs-nav-wrap{
    border-bottom: 1px solid var(--nf-sounds-secondary) !important;
}
.user-profile-edit{
    background-color: #fff;
    position: relative;
    width: 140px;
}
.user-profile-edit-button{
    position: absolute;
    top: 5px;
    right: 5px;
}