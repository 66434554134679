
.collapseBorder{
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid #000  !important;
}


.collapseBorder .ant-collapse-content{
    border-top: none !important;
}


.ant-radio-inner {
 height: 20px !important;
 width: 20px !important;
 border: 2px solid #D6C086 !important;
}
