.about-us-collapses .collapses{
    width: 72% !important;
    max-width: 676px !important;
    z-index: 3;
}
.about-us-vertical-line{
    width: 80%;
    background-color: rgb(153,153,153);
    height: 3px;
    
}
.about-us-vertical-line-center{
    width: 100% !important;
    position: relative;
    display: flex;
    justify-content: center;
}
.about-us-vertical-line-center div{
    width: 85% !important;
    background-color: rgb(153,153,153);
    height: 3px;
    position: absolute;
    top: 40px;
    z-index: 2;
    
}
.store-about-us .ant-collapse > .ant-collapse-item > .ant-collapse-header{
    border-color: var(--nf-sounds-secondary) !important;
}
.store-about-us .ant-collapse > .ant-collapse-item{
    border-color: var(--nf-sounds-secondary) !important;
}