.error-404{
    padding-top: 0;
    font-size: 400px !important;
}
.error-page-shape {
    position: absolute;
    bottom: 10%;
    left: 47%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);    
    animation: zoom-fade 5s infinite;   
}
@keyframes zoom-fade{
    0% {
        transform: scale(0.9) translateX(-50%);
    }
    50% {
        transform: scale(1) translateX(-50%);
    }
    100% {
        transform: scale(0.9) translateX(-50%);
    }
}
@media only screen and (max-width: 767px){
    .error-404{
        font-size: 300px !important
    }
    .error-page-shape{
        width:280px
    }
}
@media only screen and (max-width: 560px){
    .error-404{
        font-size: 200px !important;
    }
    .error-page-shape{
        width:200px
    }
}
@media only screen and (max-width: 420px){
    .error-404{
        font-size: 150px !important;
    }
    .error-page-shape{
        width:150px
    }
}