.post-card{
    overflow: hidden;
}
.feeds-media{
    position: relative;
    padding-top: 100%; 
    background: black;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center center !important
}
.feeds-media-auto-height{
    position: relative;
    width:'100%';   
    background-color: black;
}
.feeds-video{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.post-like-button, .post-comment-button{
    border: none !important;
    background-color: transparent;
}
/* post-card-skelton */
.post-card-skeleton{
}
/* .post-card-skeleton .ant-skeleton-button{
    display: flex !important;
    padding-top: 100% !important;
} */