.view-post-modal{
    max-height: 100vh !important;
}
.profile-header{
    position: relative;
}
.profile-header>.profile{
    z-index: 5;
    transform: translateY(13%);
}
.profile-header>div:last-child{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
}
.profile-header p{
    font-size: 75px !important;
    margin: 0 !important;
    white-space: nowrap;
    overflow: hidden;
    text-transform: uppercase;
    text-shadow:
        -1px 0px 0px var(--nf-sounds-secondary),
        1px 0px 0px var(--nf-sounds-secondary),
        0px -1px 0px var(--nf-sounds-secondary),
        0px 1px 0px var(--nf-sounds-secondary);
    color: white;
}
.profile-header p.line-one{
    transform: translate(5%, -4%);
}
.profile-header p.line-two{
    transform: translate(-5%, -4%);
}
.profile-header p.line-three{
    transform: translate(8%, -4%);
}


.story-drawer .ant-modal-body{
    padding: 0;
}


.artist-profile.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list{
    width: 100% !important;
}

.artist-profile .ant-tabs-tab{
    padding: 0 !important;
}
.artist-profile .ant-tabs-tab>.ant-tabs-tab-btn {
    width: 100% !important;
}
.artist-profile .ant-tabs-tab>.ant-tabs-tab-btn button{
    font-weight: 600 !important;
}
.artist-profile .ant-tabs-tab{
    background-color: transparent !important;
    border-color: transparent !important;
    width: 33.333%;
}
.artist-profile .ant-tabs-ink-bar{
    display: none !important;
}
.artist-profile .ant-tabs-nav-operations{
    display: none !important;
}
.item-detail-profile-picture{
    width: 370px;
    border-bottom: 1px solid var(--nf-sounds-secondary);
    display: flex;
    justify-content: center;
}
.item-detail-profile-picture .ant-avatar-square{
    border-radius: 0px !important;
    background: transparent !important;
}
.item-detail-profile-picture .ant-avatar > img{
    box-shadow: none !important;
}