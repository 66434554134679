.item-detail-left .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.item-detail-left .header>div:first-child{
    width: 90px
}
.item-detail-left .header marquee{
    width: calc(100% - 142px)
}
.item-detail-left .header>div:last-child{
    width: 34px
}
.spacewidth{
    width: 40% !important;
    margin: 0 auto
}

.spacewidth button{
    width: 60px !important;
    height: 40px !important;
    padding: 0px 20px !important;
}

.spacewidth button:nth-child(1){
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.spacewidth button:last-child{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

@media (max-width:991px){
    .spacewidth {
        width: 60% !important;
        margin: 0 auto;
    }
}
.product-left-side{
    background-color: var(--nf-sounds-secondary) !important;
    width: 100%;
    padding-top: 100%;
    background-position: center center !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
}
.product-media-auto-height{
    position: relative;
    width: 100%;   
    padding-top: 100%;
    background-color: black !important;
}
.product-video{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
}